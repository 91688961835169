import React from "react"
import Guide from "../../../components/guide2020"

const IndexPage = ({data}) => (
  <Guide
    Year={"2020"}
    Section={"barn"}
    ProductName={"barneforsikring"}
    Entity={"barn"}
    Attributes={35}
    Stars5={{
      need: "For deg som vil sikre barnet ditt på best måte mot ulykke, sykdom og uførhet",
      description: 'De aller beste barneforsikringene - 5 Defaqto stjerner',
      terms: [
        {t: "Uførekapital sum utbetalt ved 100% uførhet",c: "Må Være 500 000 kr"},
        {t: "Uførepensjon",c: "Må tilbys"},
        {t: "Uførepensjon maksimal måndelig sum",c: "Må være over 6000 kr"},
        {t: "Maks sum for medisinsk invaliditet ulykke",c: "Må være dekket for 2 MNOK"},
        {t: "Maks sum for medisinsk invaliditet etter sykdom",c: "Må være dekket for 2 MNOK"},
        {t: "Pleie og omsorgs dekning",c: "Må tilbys"},
        {t: "Uførepensjon tilbys",c: "Må tilbys"},
      ]
    }}
    Stars4={{
      need: "For deg som vil sikre barnet ditt på en god måte mot ulykke, sykdom og uførhet",
      description: 'Veldig gode barneforsikringer - 4 Defaqto stjerner',
      terms: [
        {t: "Uførepensjon",c: "Må tilbys"},
        {t: "Uførepensjon maksimal måndelig sum",c: "Må være over 4000 kroner"},
        {t: "Maks sum for medisinsk invaliditet ulykke",c: "Må være dekket for 2 MNOK"},
        {t: "Maks sum for medisinsk invaliditet etter sykdom",c: "Må være dekket for 2 MNOK"},
      ]
    }}
    Stars3={{
      need: "Gode barneforsikringer - 3 Defaqto stjerner",
      description: 'Gode forsikringer for deg som ikke har behov for de aller beste dekningene',
      terms: [
      ]
    }}
  />
)

export default IndexPage